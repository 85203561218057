import React, { Component, useState, useEffect, createRef, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'underscore';

import {SET_LOADING_OVERLAY_ENABLE} from 'actions/types';
import {getCategory} from 'reducers/categories.js';
import {fetchCategories} from 'actions/categories.js';
import {setSidebarEnable} from 'actions/sidebar.js';
import {initNavbarState} from 'actions/navbar.js';
import DatabaseService from 'services/DatabaseService.js';
import Utils from 'utils/Utils.js';
import {Buffer} from "buffer"

import Pathway from './Pathway.js';

const Languages = [
    {key:'en',  text:'English (US)',  value:'en'},
    {key:'en_ca',  text:'English (CA)',  value:'en_ca'},
    {key:'fr',  text:'Français', value:'fr'},
];


const DefaultTimes = _.range(-1, 24).map(i => {
    if(i===-1)
        return {
            key: -1,
            value: null,
            text: "(None)"
        }
    var hour = (i)%12;
    hour = (hour===0) ? 12 : hour;
    var am = (i < 12);
    var timeStep = 60;
    var timeMinutes = i*timeStep;
    return {
        key: timeMinutes,
        value: timeMinutes,
        text: String(hour) + ":00" + (am ? "AM" : "PM")
    };
});

const withDefault = (x, def) => (x!==undefined ? x : def);

const applyDefaultLocale = (obj, fields) => {
    if(!obj.locale)
        obj.locale = 'en';
    if(!obj.locales)
        obj.locales = {};
    if(!obj.locales[obj.locale]) {
        var translation = {};
        for(var i in fields) {
            translation[fields[i]] = obj[fields[i]]
        }
        obj.locales[obj.locale] = translation;
    }
};

const PathwayContainer = ({t, dispatch, match, category, clone}) => {
    const [busy, setBusy] = useState(false)
    const [objectives, setObjectives] = useState([])
    const [categoryToDisplay, setCategoryToDisplay] = useState()
    const [uploadPhotoProperty, setUploadPhotoProperty] = useState()
    const [chars_left, setChars_left] = useState()
    const [descriptionCharsShow, setDescriptionCharsShow] = useState(false)
    const [uploadPhotoProps, setUploadPhotoProps] = useState()
    const [titleCharsShow, setTitleCharsShow] = useState(false)
    const [categoryState, setCategoryState] = useState({})
    const [error, setError] = useState()

    const deleteModalRef = createRef()
    const refLevel = createRef()
    const refSection = createRef()
    const refChallenge = createRef()
    const refChallengeAnswer = createRef()
    const fileSelector = useRef()
    const history = useHistory()

    useEffect(() => {
        getInitialCategoryState(category)
        var id = match.params.id;
        var update = (id!==undefined);
        dispatch(initNavbarState({enable:true, backButtonEnable:true, uploadEnable: true, onUpload: onUpload, title: update ? t('Edit Pathway') : t('Add New Pathway')}))
        dispatch(setSidebarEnable(false))
        loadData()
        return () => dispatch(initNavbarState({enable:false}))
    }, [])

    const onUpload = () => {
        fileSelector.current.click()
    }

    const finishUpload = async (event) => {
        var file = event.target.files[0];
        event.target.value=null;

        var reader = new FileReader();
        reader.onerror= (e) => { window.alert(e)  };
        reader.onload = async (e) => {
            setBusy(true)
            category = JSON.parse(reader.result);
            if (categoryState?.tag && category?.tag && category.tag !== categoryState.tag) {
                setError('Uploaded tag does not match current Activity Pack')
            } else {
                setError()
                setInitialCategoryState(category)
            }
            setBusy(false)
        }
        reader.readAsText(file, 'UTF-8');
    }

    const hashObjectiveCategories = (objectiveCategories) => {
        objectiveCategories = objectiveCategories || [];
        return objectiveCategories.reduce((m,oc) => {m[oc.objectiveId] = oc; return m;}, {});
    }

    const setInitialCategoryState = (category) => {
        applyDefaultLocale(category, ['title', 'description'])
        var result = {
              id: category.id,
              locales: category.locales,
              locale: withDefault(category.locale, 'en'),
              defaultTime: withDefault(category.defaultTime, ''),
              autoSubscribe: withDefault(category.autoSubscribe, false),
              companyId: withDefault(category.companyId, localStorage.companyId),
              categoryLevels: withDefault(category.categoryLevels, []),
              challenges: withDefault(category.challenges, []),
              objectiveCategories: hashObjectiveCategories(withDefault(category.objectiveCategories, [])),
              badge: withDefault(category.badge, undefined),
              leaderboardBanner: withDefault(category.leaderboardBanner, undefined),
              hasPathway            : withDefault(category.hasPathway, true),
              hasProgress           : withDefault(category.hasProgress, true),
              tag: category.tag
        };
        for(var i in result.challenges) {
            var challenge = result.challenges[i];
            applyDefaultLocale(challenge, ['title', 'description'])
            var answers = challenge.challengeAnswers;
            for(var j in answers)
                applyDefaultLocale(answers[j], ['label'])
        }
        for(var i in result.categoryLevels) {
            var level = result.categoryLevels[i];
            for(var j in level.categoryLevelSections) {
                var section = level.categoryLevelSections[j];
                for(var k in section.challenges) {
                    var challenge = section.challenges[k];
                    applyDefaultLocale(challenge, ['title', 'description'])
                    var answers = challenge.challengeAnswers;
                    for(var m in answers)
                        applyDefaultLocale(answers[m], ['label'])
                }
            }
        }
        setCategoryState(result)
        return result;
    }

    const getInitialCategoryState = (category) => {
        if(clone) {
            category = JSON.parse(sessionStorage.clonedCategory);
            console.log('clonedCategory:');
            console.log(category)
        }
        var category = category || {};
        return setInitialCategoryState(category)        
    }

    const onChangeDropdown = (event, {value, name}) => {
        console.log('onChangeDropdown', {name, value});
        setCategoryState(prevCategory => (Utils.set({...prevCategory}, name, value)))
    }

    const onChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;
        setChars_left(value.length)
        setCategoryState(prevCategory => (Utils.set({...prevCategory}, name, value)))
    }

    const saveAssets = (category, persistedCategory) => {
        var p = Promise.resolve(category);

        if(category.badge && category.badge.enabled === false) {
            p = p.then(function() {
                return DatabaseService.goals_categoryId_badge_del({categoryId:category.id})
                    .then(asset => {
                        category.badge = null;
                    });
            })
        } else if(category.badge && category.badge.dataURL) {
            p = p.then(function() {
                var obj = {
                    categoryId: persistedCategory.id,
                    badge : {dataURL:category.badge.dataURL}
                }
                return DatabaseService.goals_categoryId_badge_post(obj)
                    .then(asset => {
                        category.badge = asset;
                    });
            })
        }

        if(category.leaderboardBanner && category.leaderboardBanner.enabled === false) {
            p = p.then(function() {
                return DatabaseService.goals_categoryId_leaderboardBanner_del({categoryId:category.id})
                    .then(asset => {
                        category.leaderboardBanner = null;
                    });
            })
        } else if(category.leaderboardBanner && category.leaderboardBanner.dataURL) {
            p = p.then(function() {
                var obj = {
                    categoryId: persistedCategory.id,
                    leaderboardBanner : {dataURL:category.leaderboardBanner.dataURL}
                }
                return DatabaseService.goals_categoryId_leaderboardBanner_post(obj)
                    .then(asset => {
                        category.leaderboardBanner = asset;
                    })
            })
        }

        if(category.hasPathway === true) {
            for(var i in category.categoryLevels) {
                var level = category.categoryLevels[i];
                for(var j in level.categoryLevelSections) {
                    var section = level.categoryLevelSections[j];
                    var index = 0;
                    var challenges = section.challenges || [];
                    for(var k = 0; k < challenges.length; k++) {
                        let challenge = challenges[k];
                        if(challenge.enabled === true) {
                            let persistedChallenge = persistedCategory.categoryLevels[i].categoryLevelSections[j].challenges[k];

                            if(challenge.image && challenge.image.enabled === false) {
                                p = p.then(function() {
                                    return DatabaseService.activities_challengeId_image_del({challengeId:persistedChallenge.id})
                                        .then(asset => {
                                            challenge.image = null;
                                        })
                                })
                            } else if(challenge.image && challenge.image.dataURL) {
                                p = p.then(function() {
                                    let obj = {
                                        challengeId: persistedChallenge.id,
                                        image : {dataURL:challenge.image.dataURL}
                                    }
                                    return DatabaseService.activities_challengeId_image_post(obj)
                                        .then(asset => {
                                            challenge.image = asset;
                                        })
                                })
                            }
                        }
                    }
                }
            }
        }
        else {
            var challenges = category.challenges || [];
            for(var k = 0; k < challenges.length; k++) {
                let challenge = challenges[k];
                if(challenge.enabled === true) {
                    let persistedChallenge = persistedCategory.challenges[k];

                    if(challenge.image && challenge.image.enabled === false) {
                        p = p.then(function() {
                            return DatabaseService.activities_challengeId_image_del({challengeId:persistedChallenge.id})
                                .then(asset => {
                                    challenge.image = null;
                                })
                        })
                    } else if(challenge.image && challenge.image.dataURL) {
                        p = p.then(function() {
                            let obj = {
                                challengeId: persistedChallenge.id,
                                image : {dataURL:challenge.image.dataURL}
                            }
                            return DatabaseService.activities_challengeId_image_post(obj)
                                .then(asset => {
                                    challenge.image = asset;
                                })
                        })
                    }
                }
            }
        }
        return p;
    }

    const getBasepath = (levelIndex, sectionIndex, challengeIndex, challengeAnswerIndex) => {
        if(levelIndex===undefined && sectionIndex===undefined) {
            if(challengeAnswerIndex===undefined) {
                return 'challenges.'+challengeIndex;
            } else {
                return 'challenges.'+challengeIndex+'.challengeAnswers.'+challengeAnswerIndex;
            }
        } else {
            if(challengeAnswerIndex===undefined) {
                return 'categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex+'.challenges.'+challengeIndex;
            } else {
                return 'categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex+'.challenges.'+challengeIndex+'.challengeAnswers.'+challengeAnswerIndex;
            }
        }
    }

    const onSubmit = (event) => {
        event && event.preventDefault();

        if(!isValid()) {
            return;
        }

        var update = (categoryState.id!==undefined);
        var method = update ? 'updateCategory' : 'createCategory';

        var category = {
              id                    : categoryState.id,
              locales               : categoryState.locales,
              locale                : categoryState.locale,
              defaultTime           : categoryState.defaultTime,
              autoSubscribe         : categoryState.autoSubscribe,
              companyId             : categoryState.companyId,
              objectiveCategories   : Object.keys(categoryState.objectiveCategories).map(objectiveId => ({...categoryState.objectiveCategories[objectiveId], objectiveId})),
              categoryLevels        : categoryState.categoryLevels,
              challenges            : categoryState.challenges,
              badge                 : categoryState.badge,
              leaderboardBanner     : categoryState.leaderboardBanner,
              icon                  : 'icon-myHealthSphere-logo', //deprecated
              hasPathway            : categoryState.hasPathway,
              hasProgress           : categoryState.hasPathway,//fixme - what is hasProgress
              tag                   : categoryState.tag !== '' ? categoryState.tag : undefined
        };

        dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:true});
        var dataObj = { category : { base64: Buffer.from(JSON.stringify(category)).toString('base64') }, categoryId: categoryState.id };
        DatabaseService[method](dataObj)
            .then(result => {
                return saveAssets(category, result.category);
            })
            .then(result => {
                history.push('/goals');
            }, error => {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(result => {
                dispatch({type: SET_LOADING_OVERLAY_ENABLE, enable:false});
            });
    }

    const deleteActivityPack = (deleteYes) => {
        if(deleteYes){
            var id = match.params.id;
            setBusy(true)
            return DatabaseService.deleteCategoryById({id:id})

            .then(()=>{
                setBusy(false)
                history.push('/goals');
            })
            .catch((error)=> {
                window.alert('An error occurred - check logs');
                console.error(error);
                setBusy(false)
            })
        }
    }

    const loadData = () => {
        var id = match.params.id
        setBusy(true)

        return Promise.all([
                DatabaseService.getObjectives({subscribed: true}),
                id ? DatabaseService.getCategoryById({id:id, pathway:true, challenges:true}) : null,
            ])
            .then(([result, resultGetCategory])=>{
                setObjectives(result.objectives)
                setCategoryState(prevCategory => {
                    return {
                        ...prevCategory,
                        ...(resultGetCategory ? getInitialCategoryState(resultGetCategory.category) : {})
                    }
                })
            })
            .catch((error)=> {
                window.alert('An error occurred - check logs');
                console.error(error);
            })
            .then(()=>{
                setBusy(false)
            });
    }

    const onToggle = (event, {name, checked}) => {
        console.log('onToggle', {name, checked});
        setCategoryState(prevCategory => (Utils.set({...prevCategory}, name, checked)))
    }

    const onTogglePublic = () => {
        setCategoryState(prevCategory => {
            return {
                ...prevCategory,
                companyId: Boolean(prevCategory.companyId) ? null : localStorage.companyId
            }
        })
    }

    const convertPathway = (state, hasPathway) => {
        if(hasPathway) {
            return convertToPathway(state)
        } else {
            return convertFromPathway(state)
        }
    }

    const convertToPathway = (state) => {
        var result = {categoryLevels:[], challenges:[]};
        if(state.challenges && state.challenges.length > 0) {
            result.categoryLevels.push(getNewCategoryLevel());
            result.categoryLevels[0].categoryLevelSections.push(getNewCategoryLevelSection());
            result.categoryLevels[0].categoryLevelSections[0].challenges = state.challenges;
        }
        return result;
    }

    const convertFromPathway = (state) => {
        var result = {categoryLevels:[], challenges:[]};
        for(var i = 0; i < state.categoryLevels.length ; i++) {
            var cl = state.categoryLevels[i];
            for(var j = 0; j < cl.categoryLevelSections.length; j++) {
                var cls = cl.categoryLevelSections[j];
                result.challenges = result.challenges.concat(cls.challenges);
            }
        }
        return result;
    }

    const onToggleHasPathway = () => {
        setCategoryState(prevCategory => {
            let hasPathway = !prevCategory.hasPathway
            return {
                ...prevCategory,
                hasPathway,
                ...convertPathway(prevCategory, hasPathway)
            }
        })
    }

    const getNewCategoryLevel = () => {
        return {
             enabled:true,
             allowRepeat:'true',
             isOpen:true,
             categoryLevelSections:[],
             key:String(Math.random()),
        };
    }

    const onAddLevel = () => {
        var categoryLevels = categoryState.categoryLevels;
        var newCategoryLevels = categoryLevels.concat([getNewCategoryLevel()]);
        setCategoryState(prevCategory => {
            return {
                ...prevCategory,
                categoryLevels: newCategoryLevels
            }
        })
    }

    const getNewCategoryLevelSection = () => {
        return {
            enabled:true,
            type:'0',
            order:'0',
            countRepeats:'true',
            awardRepeats:'true',
            isOpen:true,
            challenges:[],
            key:String(Math.random())
        };
    }

    const onAddSection = (levelIndex) => {
        var categoryLevelSections = categoryState.categoryLevels[levelIndex].categoryLevelSections;
        var newCategoryLevelSections = categoryLevelSections.concat([getNewCategoryLevelSection()]);
        setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex+'.categoryLevelSections', newCategoryLevelSections)));
    }

    const getNewChallenge = () => {
        return {
            enabled:true,
            isOpen:true,
            locales: {
                // en:{title:'', description:''},
                // fr:{title:'', description:''}
            },
            key:String(Math.random())
        }
    }

    const onAddChallenge = (levelIndex, sectionIndex) => {
        console.log('onAddChallenge', {levelIndex, sectionIndex});
        if(categoryState.hasPathway) {
            let challenges= categoryState.categoryLevels[levelIndex].categoryLevelSections[sectionIndex].challenges
            let newChallenges = challenges.concat([getNewChallenge()])
            setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex+'.challenges', newChallenges)))
        } else {
            let challenges= categoryState.challenges;
            let newChallenges = challenges.concat([getNewChallenge()]);
            setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'challenges', newChallenges)))
        }
    }

    const getNewChallengeAnswer = () => {
        return {
            enabled:true,
            isOpen:true,
            label:'',
            value:'',
            locales: {
                 //en:{label:''},
                 //fr:{label:''}
            },
            key:String(Math.random())
        };
    }

    const onAddChallengeAnswer = (levelIndex, sectionIndex, challengeIndex) => {
        if(categoryState.hasPathway) {
            var challengeAnswers = categoryState.categoryLevels[levelIndex].categoryLevelSections[sectionIndex].challenges[challengeIndex].challengeAnswers || [];
            var newChallengeAnswers = challengeAnswers.concat([getNewChallengeAnswer()])
            setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex+'.challenges.'+challengeIndex+'.challengeAnswers', newChallengeAnswers)))
        } else {
            var challengeAnswers = categoryState.challenges[challengeIndex].challengeAnswers || [];
            var newChallengeAnswers = challengeAnswers.concat([getNewChallengeAnswer()]);
            setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'challenges.'+challengeIndex+'.challengeAnswers', newChallengeAnswers)))
        }
    }

    const onSelectChallengeType = (levelIndex, sectionIndex, challengeIndex, typeId) => {
        if(levelIndex===undefined && sectionIndex===undefined) {
            setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'challenges.'+challengeIndex+'.type', typeId)))
        } else {
            setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex+'.challenges.'+challengeIndex+'.type', typeId)))
        }
    }

    const onHideTab = (levelIndex, sectionIndex, challengeIndex, challengeAnswerIndex) => {
        if(categoryState.hasPathway) {
            if(challengeAnswerIndex!==undefined) {
                let challengeAnswer = categoryState.categoryLevels[levelIndex].categoryLevelSections[sectionIndex].challenges[challengeIndex].challengeAnswers[challengeAnswerIndex];
                let newChallengeAnswer = {...challengeAnswer, isOpen:!challengeAnswer.isOpen};
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex+'.challenges.'+challengeIndex+'.challengeAnswers.'+challengeAnswerIndex, newChallengeAnswer)))
            } else if(challengeIndex!==undefined) {
                let challenge = categoryState.categoryLevels[levelIndex].categoryLevelSections[sectionIndex].challenges[challengeIndex];
                let newChallenge = {...challenge, isOpen:!challenge.isOpen};
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex+'.challenges.'+challengeIndex, newChallenge)))
            } else if (sectionIndex!==undefined) {
                let categoryLevelSection = categoryState.categoryLevels[levelIndex].categoryLevelSections[sectionIndex];
                let sectionContent = document.getElementById("sectionContent" + levelIndex + sectionIndex);
                if(!categoryLevelSection.isOpen)
                    sectionContent.style.display = 'block';
                else
                    sectionContent.style.display ='none';
                let newCategoryLevelSection = {...categoryLevelSection, isOpen:!categoryLevelSection.isOpen};
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex, newCategoryLevelSection)))
            } else {
                let categoryLevel = categoryState.categoryLevels[levelIndex];
                let levelContent = document.getElementById("levelContent" + levelIndex);
                if(!categoryLevel.isOpen)
                    levelContent.style.display = 'block';
                else
                    levelContent.style.display = 'none';
                let newCategoryLevel = {...categoryLevel, isOpen:!categoryLevel.isOpen};
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex, newCategoryLevel)))
            }
        } else {
            if(challengeAnswerIndex!==undefined) {
                let challengeAnswer = categoryState.challenges[challengeIndex].challengeAnswers[challengeAnswerIndex];
                let newChallengeAnswer = {...challengeAnswer, isOpen:!challengeAnswer.isOpen};
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'challenges.'+challengeIndex+'.challengeAnswers.'+challengeAnswerIndex, newChallengeAnswer)))
            } else if(challengeIndex!==undefined) {
                let challenge = categoryState.challenges[challengeIndex];
                let newChallenge = {...challenge, isOpen:!challenge.isOpen};
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'challenges.'+challengeIndex, newChallenge)))
            }
        }
    }

    const onDeleteTab = (deleteYes, levelIndex, sectionIndex, challengeIndex, challengeAnswerIndex) => {
        if(deleteYes && categoryState.hasPathway) {
            if(challengeAnswerIndex!==undefined) {
                let challengeAnswers = categoryState.categoryLevels[levelIndex].categoryLevelSections[sectionIndex].challenges[challengeIndex].challengeAnswers;
                let newChallengeAnswers = challengeAnswers.filter((x,i) => i!==challengeAnswerIndex);
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex+'.challenges.'+challengeIndex+'.challengeAnswers', newChallengeAnswers)))
            } else if(challengeIndex!==undefined) {
                let challenges = categoryState.categoryLevels[levelIndex].categoryLevelSections[sectionIndex].challenges;
                let newChallenges = challenges.filter((x,i) => i!==challengeIndex);
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex+'.challenges', newChallenges)))
            } else if (sectionIndex!==undefined) {
                let categoryLevelSections = categoryState.categoryLevels[levelIndex].categoryLevelSections;
                let newCategoryLevelSections = categoryLevelSections.filter((x,i) => i!==sectionIndex);
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels.'+levelIndex+'.categoryLevelSections', newCategoryLevelSections)))
            } else {
                let categoryLevels = categoryState.categoryLevels;
                console.log('Category Levels', categoryLevels);
                let newCategoryLevels = categoryLevels.filter((x,i) => i!==levelIndex);
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'categoryLevels', newCategoryLevels)))
            }
        } else {
            if(challengeAnswerIndex!==undefined) {
                let challengeAnswers = categoryState.challenges[challengeIndex].challengeAnswers;
                let newChallengeAnswers = challengeAnswers.filter((x,i) => i!==challengeAnswerIndex);
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'challenges.'+challengeIndex+'.challengeAnswers', newChallengeAnswers)))
            } else if(challengeIndex!==undefined) {
                let challenges = categoryState.challenges;
                let newChallenges = challenges.filter((x,i) => i!==challengeIndex);
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, 'challenges', newChallenges)))
            }
        }
    }

    const onUploadPhoto = (uploadPhotoProps) => {
        setUploadPhotoProps(uploadPhotoProps)
    }

    const onConfirmPhoto = (propertyName, photo) => {
        setCategoryState(prevCategory => {
            let categoryState = Utils.set({...prevCategory}, propertyName, photo);
            let imagePathSplit = propertyName.split('.')
            let imagePath = imagePathSplit.filter((x,i)=> i<imagePathSplit.length-1).join('.');
            categoryState = Utils.set({...categoryState}, imagePath+'.enabled', true);
            console.log('onConfirmPhoto', imagePath, propertyName);
            return categoryState;

        })
    }

    const onCancelPhoto = (name) => {
        console.log('onCancelPhoto', name);
        setCategoryState(prevCategory => (Utils.set({...prevCategory}, name+'.enabled', false)));
    }

    const validateObjectiveCategories = (objectiveCategories) => {
        var valid = true;
        Object.keys(objectiveCategories).forEach(key => {
            var oc = objectiveCategories[key];
            if(oc.enabled && !_.isFinite(oc.quality)) {
                valid = false;
            }
        });
        return valid;
    }

    const isValid = () => {
        var locale = categoryState.locales && categoryState.locales[categoryState.locale];
        var objectiveCategoriesValid = categoryState.objectiveCategories && validateObjectiveCategories(categoryState.objectiveCategories);
        return (Boolean(locale) &&
                Boolean(locale.title) &&
                Boolean(locale.description) &&
                objectiveCategoriesValid);
    }

    const onAutoCaption = (path, imgUrl) => {
        var key = localStorage.getItem('Ocp-Apim-Subscription-Key');
        if(!key) {
            alert('Missing API Key for Image Recognition')
            return;
        }

        var head = {};
        head['Ocp-Apim-Subscription-Key'] = localStorage.getItem('Ocp-Apim-Subscription-Key');
        head['Content-Type'] = 'application/json';

        var apiUrl = 'https://optimity-prod-us-east-compvis.cognitiveservices.azure.com/computervision/imageanalysis:analyze?features=caption,read&model-version=latest&language=en&api-version=2024-02-01';

        var url = encodeURI(apiUrl);
        var init = {
            //credentials: "include",
            method: 'POST',
            headers: head
        }

        //categoryLevels.'+levelIndex+'.categoryLevelSections.'+sectionIndex+'.challenges.'+challengeIndex
        init.body = JSON.stringify({url:imgUrl});

        return fetch(url, init).then(r => r.json()).then((resp) => {
            if(resp && resp.captionResult && resp.captionResult.text) {
                var value = resp.captionResult.text;
                setCategoryState(prevCategory => (Utils.set({...prevCategory}, path, value)));
            }
        });
    }
    var id = match.params.id;
    var update = (id!==undefined);
    return (
        <Pathway
            fileSelector={fileSelector}
            finishUpload={finishUpload}
            error={error}
            categoryLevels={categoryState.categoryLevels}
            challenges={categoryState.challenges}
            locale={categoryState.locale}
            locales={categoryState.locales}
            tag={categoryState.tag}
            languages={categoryState.languages}
            defaultTimes={categoryState.defaultTimes}
            defaultTime={categoryState.defaultTime}
            objectives={objectives}
            autoSubscribe={categoryState.autoSubscribe}
            companyId={categoryState.companyId}
            hasPathway={categoryState.hasPathway}
            objectiveCategories={categoryState.objectiveCategories}
            uploadPhotoProps={uploadPhotoProps}
            badge={categoryState.badge}
            leaderboardBanner={categoryState.leaderboardBanner}
            busy={busy || !categoryState.challenges}
            update={update}
            valid={isValid()}
            master={localStorage.master==='true'}
            languages={Languages}
            defaultTimes={DefaultTimes}
            onSubmit={onSubmit}
            onChange={onChange}
            onChangeDropdown={onChangeDropdown}
            onToggle={onToggle}
            onTogglePublic={onTogglePublic}
            onToggleHasPathway={onToggleHasPathway}
            onAddLevel={onAddLevel}
            onAddSection={onAddSection}
            onAddChallenge={onAddChallenge}
            onAddChallengeAnswer={onAddChallengeAnswer}
            onSelectChallengeType={onSelectChallengeType}
            onHideTab={onHideTab}
            onDeleteTab={onDeleteTab}
            onUploadPhoto={onUploadPhoto}
            onConfirmPhoto={onConfirmPhoto}
            onCancelPhoto={onCancelPhoto}
            getBasepath={getBasepath}
            onDeleteActivityPack={deleteActivityPack}
            deleteModalRef = {deleteModalRef}
            valueChange = {chars_left}
            refLevel = {refLevel}
            refSection = {refSection}
            refChallenge = {refChallenge}
            refChallengeAnswer = {refChallengeAnswer}
            onAutoCaption = {onAutoCaption}
        />
    );
}
export default withRouter(connect()(withTranslation('categories')(PathwayContainer)));
