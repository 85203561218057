import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import {Link} from 'react-router-dom';
import {Bar, Line} from 'react-chartjs-2';
import {Tab, Icon, Card, Button, Grid, Header, Message, Label, Segment, Popup} from 'semantic-ui-react';

import Utils from 'utils/Utils.js'
import MathUtils from 'utils/MathUtils.js'
import LoadInline from 'modules/loading/LoadInline.js';
import {colors, fontSizes, spacings, shadow, buttons} from 'style';
import EngagementCard from './EngagementCard.js';

import './dashboard.css';
import moment from 'moment';

var style = {
    loaderContainer: {
        height: '100%',
    },
    content: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        minHeight: '100%',
        padding: spacings.base,
        maxWidth:'1300px'
    },
    title: {
        fontSize: fontSizes.h4,
        color: colors.themeDarkerGrey,
        lineHeight: '1.5',
    },
    section: {
        fontSize: fontSizes.body,
        lineHeight: '1.5',
        backgroundColor: '#fff',
        boxShadow: shadow,
    },
    card: {
        width: 'auto',
        background: 'none',
    },
    break: {
        flexBasis: '100%',
        width: '0px', 
        height: '0px',
        overflow: 'hidden',
    },
    engagement: {
        minHeight: '250px',
  
        margin: spacings.tiny,
    },
    engagementCard : {
        width:"auto",
        minWidth:"50%"
    },
    graphCard: {
        //width: 'calc(50% - 100px)',
        width:"auto",
        minWidth:"50%"
    },
    graphs: {
        width: '100%',
        height: '250px',
        padding: spacings.base
    },
    lineChart: {
        position:"absolute"
    },
    category: {
        display: 'inline-block',
        width: '100%',
        padding: spacings.base,
        margin: spacings.tiny,
        minWidth:'300px',
        
    },
    categoryIcon: {
        float: 'right',
        fontSize: '40px',
        color: '#379f07',
    },
    categoryTitle: {
        fontSize: fontSizes.bodyL
    },
    categorySubtitle: {
        fontSize: fontSizes.body,
        color: colors.themeDarkerGrey,
    },
    topTeam: {
        paddingTop: spacings.base,
        paddingBottom: spacings.base,
        paddingLeft: spacings.base,
        paddingRight: spacings.base,
        textAlign: 'center',
        color: '#fff',
        backgroundColor: colors.teamPurple,
        width: 'calc(100% - 8px)',
        minHeight: '234px',
        marginTop:spacings.tiny,
        marginLeft:spacings.tiny,
        display:"table-cell"
    },
    teamAvatar: {
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        width: '100px',
        height: '100px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    cards: {
        //marginTop: '34px',
        display: 'flex',
        flexWrap: 'wrap',
    },
    leaderboard: {
        display: 'inline-block',
        margin:spacings.tiny,
        padding: spacings.base,
        width: '100%',
        height: '270px',
        overflowY: 'auto',
    },
    leaderboardRow: {
        //lineHeight: '64px',
        //marginTop: spacings.tiny,
        //marginBottom: spacings.tiny,
    },
    leaderboardNumber: {
        display: 'inline-block',
        width: '35px',
        height: '35px',
        //borderRadius: '50%',
        //backgroundColor: colors.themeDarkGrey,
        color: colors.themeDarkGrey,
        textAlign: 'center',
        lineHeight: '35px',
        fontSize: fontSizes.bodyL,
        marginRight: spacings.tiny,
    },
    leaderboardPoints: {
        display: 'inline-block',
        paddingLeft: spacings.tiny,
        paddingRight: spacings.tiny,
    },
    leaderboardStar: {
        //display: 'inline-block',
        color: colors.pointsYellow,
        //paddingLeft: spacings.tiny,
        //paddingRight: spacings.tiny,
    },
    leaderboardAvatar: {
        verticalAlign: 'middle',
        display: 'inline-block',
        width: '48px',
        height: '48px',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        borderRadius:'50%',
        //marginLeft: spacings.tiny,
        //marginRight: spacings.tiny,
        textAlign: 'center',
    },
    leaderboardUserIcon: {
        display: 'inline-block',
        fontSize: '40px',
    },
    leaderboardEmail: {
        //display: 'inline-block',
        //paddingLeft: spacings.tiny,
        //paddingRight: spacings.tiny,
    },
    topIncentives: {
        display: 'inline-block',
        padding: spacings.base,
        margin:spacings.tiny,
        minWidth: '180px',
        minHeight: '270px',
    },
    topIncentivesTable: {
        textAlign: 'left',
        borderSpacing: '0',
    },
    topIncentivesTHead: {
        fontWeight: 'bold',
        fontSize: fontSizes.bodyL,
        paddingTop: '2px',
        paddingBottom: '2px',
    },
    topIncentivesItemHeader: {
        width: '100%',
    },
    topIncentivesTR: {
        borderTop: '1px solid ' + colors.themeDarkerGrey,
    },
    topIncentivesTD: {
        paddingRight: spacings.large,
    },
    platformEngagement: {
        margin:spacings.tiny,
        height: '270px',
    },
    barChart: {
        height: '150px',
        //width:'150px',
        paddingRight:'10px'
    },
    platformEngagementLegend: {
        height: '40px',
        marginTop: spacings.base,
    },
    platformEngagementLegendItem: {
        display: 'inline-block',
        width: '50%',
        textAlign: 'center',
        fontSize: fontSizes.h4,
        color: colors.themeDarkGrey,
    },
    platformEngagementXAxis: {
        height: '40px',
        position: 'relative',
        top: '-10px',
        left: '0px',
    },
    platformEngagementXAxisItem: {
        display: 'inline-block',
        width: '50%',
        textAlign: 'center',
        fontSize: fontSizes.h2,
        color: colors.themeDarkGrey,
    },
    button : {
        ...buttons.base
    }
};


const Dashboard = ({
    t, 
    busy, 
    registrations,
    participation,
    devices,
    teams,
    rewards,
    users,
    categories,
    platformEngagement,
    platformChartOptions,
    graphData,
    graphOptions,
    graphPanes,
    selectedGraph,
    onChangeGraph,
    onExportCsv,
    setDownloadLink
}) => {
    return (
        busy ? (
            <div style={style.loaderContainer}>
                <LoadInline centered vcentered /> 
            </div>
        ) : (
            <div style={style.content}>
                
                <Grid columns="equal">
                <Grid.Row>
                <Grid.Column style={style.engagementCard}>
                    <div style={style.title}>{t('Overall Engagement')}</div>
                    <div style={{...style.section, ...style.engagement}}>
                        <div style={style.cards}>
                            <EngagementCard title={t('Registered Users')}
                                text={registrations.total + ' / ' + registrations.employees}
                                progress={MathUtils.coalesceNumber(registrations.total / registrations.employees, 0)}
                                delta={MathUtils.coalesceNumber(registrations.total - registrations.previousTotal, null)}
                                iconClass='icon-team'
                                color1='#62a8ea'
                                color0='#f7fafb'
                                opacity1={1}
                                opacity0={1}
                                tooltip={`Last 30 days change in registered members`}/>
                            <EngagementCard title={t('Wearables')}
                                text={devices.total + ' / ' + registrations.total}
                                progress={MathUtils.coalesceNumber(devices.total / registrations.total, 0)}
                                delta={MathUtils.coalesceNumber((devices.total / registrations.total) * 100 - (devices.previousTotal / registrations.previousTotal) * 100, null)}
                                deltaUnit='%'
                                iconClass='heart outline'
                                color1='#57c7d4'
                                color0='#f7fafb'
                                opacity1={1}
                                opacity0={1}
                                tooltip={`Last 30 days change in members with connected devices`}/>
    
                            <EngagementCard title={t('Engagement')}
                                text={MathUtils.coalesceNumber((participation.count / registrations.total * 100).toFixed(0), '') + '%'}
                                progress={MathUtils.coalesceNumber(participation.count / registrations.total, 0)}
                                delta={MathUtils.coalesceNumber((participation.count / registrations.total) * 100 - (participation.previousCount / registrations.previousTotal) * 100, null)}
                                deltaUnit='%'
                                iconClass='icon-three-star'
                                color1='#926dde'
                                color0='#f7fafb'
                                opacity1={1}
                                opacity0={1}
                                tooltip={`Last 30 days vs Previous 30 days`}/>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column style={style.graphCard} >
            
                    <Tab panes={graphPanes} activeIndex={selectedGraph} onTabChange={onChangeGraph} />
                    <div style={{...style.section, ...style.graphs}} className='lineCanvas'>
                        {
                            graphData.type === 'line' ?
                            <Line data={graphData.data} options={graphOptions} /> :
                            <Bar data={graphData.data} options={graphOptions} />
                        }
                    </div>
         
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                <Grid.Column style={{minWidth:'300px'}}>
                <Card.Group className='borderless'>
                {Boolean(categories && categories.length>0) &&
                <Card style={{...style.card, width:'100%'}}>
                    <div>
                        <div style={style.title}>{t('Popular Goals')}</div>
    
                    <Grid columns={'equal'} padded={false} style={{marginTop:"0", marginBottom:"0"}}>
                    {categories.reduce((result, value, index, array) => {
                        if (index % 2 === 0)
                          result.push(array.slice(index, index + 2));
                        return result;
                      }, []).map((pair) => {
                        return <Grid.Row style={{padding:"0"}}>
                        {
                            pair.map((category)  =>{
                                return <Grid.Column style={{minWidth:'300px'}}><div key={category.id} style={{...style.section, ...style.category}}>
                                <i className={category.category.icon} style={style.categoryIcon}></i>
                                <div>
                                    <div style={style.categoryTitle}>
                                        {t('people_are_working_on', {count:category.userCount})}
                                    </div>
                                    <div style={style.categorySubtitle}>
                                        {category.category.title}
                                    </div>
                                </div>
                            </div></Grid.Column>
                            })
                        }
                        </Grid.Row>
                    })}
                    </Grid>
                    </div>
                </Card>
                }
                </Card.Group>
                </Grid.Column>
                <Grid.Column className="wide" style={{width:'225px'}}>
                <Card.Group className='borderless'>
                {Boolean(teams && teams.length>0) &&
                    <Card style={{...style.card, width:'100%'}} className='borderless'>
                        <div style={style.title}>{t('Top Team')}</div>
                        <div style={{...style.section, ...style.topTeam}}>
                            <div style={{display:'inline-block'}}>
                                <div>{teams[0].name}</div>
                                {Boolean(teams[0].avatar) &&
                                    <div style={{...style.teamAvatar, backgroundImage:Utils.url(teams[0].avatar.url)}} />
                                }
                                <div>{teams[0].points} pts</div>
                            </div>
                        </div>
                    </Card>
                }
                </Card.Group>
                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                { localStorage.master === 'true' &&
                <Grid.Column className="wide" style={{minWidth:'200px', width:'20%'}}>    
                <Card.Group className='borderless'>
                    <Card style={{...style.card, width:"100%", marginRight:"0"}}>
                        <div style={style.title}>{t('User Devices')}</div>
                        <div style={{...style.section, ...style.platformEngagement}}>
                            <div style={style.platformEngagementLegend}>
                                <div style={style.platformEngagementLegendItem}>{platformEngagement.iosEngagement}</div>
                                <div style={style.platformEngagementLegendItem}>{platformEngagement.androidEngagement}</div>
                            </div>
                            <div style={style.barChart}  className='lineCanvas'>
                                <Bar data={platformEngagement.chartData}  options={platformChartOptions} />
                            </div>
                            <div style={style.platformEngagementXAxis}>
                                <div style={style.platformEngagementXAxisItem}><Icon name='apple'/></div>
                                <div style={style.platformEngagementXAxisItem}><Icon name='android'/></div>
                            </div>
                        </div>
                        <div />
                    </Card>
                </Card.Group>
                </Grid.Column>
                 }
                 { localStorage.master === 'true' &&
                <Grid.Column style={{minWidth:'375px'}}>
                    <Card.Group className='borderless'>
                    <Card style={{...style.card, width:"100%", marginRight:"0"}}>
                        <div style={style.title}>{t('Top Incentives')}</div>
                        <div style={{...style.section, ...style.topIncentives}}>
                            <table style={style.topIncentivesTable}>
                                <thead style={style.topIncentivesTHead}><tr><th style={style.topIncentivesItemHeader}>{t('Item')}</th><th>{t('Redeemed')}</th></tr></thead>
                                <tbody>
                                {rewards.map((reward,i)=>(
                                    <tr key={reward.id} style={{...style.topIncentivesTR, backgroundColor: Boolean(i%2) ? '#fff' : colors.themeGrey }}>
                                        <td style={style.topIncentivesTD}>{reward.reward.title} ${reward.reward.price/100}</td>
                                        <td style={style.topIncentivesTD}>{reward.redeems}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table> 
                        </div>
                        <div />
                    </Card>
                    </Card.Group>
                </Grid.Column>
                }

            <Grid.Column style={{minWidth:'450px'}}>
                <Card.Group className='borderless'>
                <Card style={{...style.card, width:"100%"}}>
                    <div style={style.title}>{t('leaderboard_title')}</div>
                    <Grid style={{...style.section, ...style.leaderboard}} columns={16}>
                        {users.map((user, i)=>(
                            <Grid.Row key={user.id} style={style.leaderboardRow}>
                                <Grid.Column width={1} style={style.leaderboardNumber} verticalAlign={'middle'}>{i+1}</Grid.Column>
                                <Grid.Column width={3} verticalAlign={'middle'}>
                                    {Boolean(user.userProfile && user.userProfile.avatar && user.userProfile.avatar.url) ? (
                                        <div style={{...style.leaderboardAvatar, backgroundImage:Utils.url(user.userProfile.avatar.url)}} />
                                    ) : (
                                        <div style={{...style.leaderboardAvatar}}><i style={style.leaderboardUserIcon} className='icon-user'/></div>
                                    )}
                                </Grid.Column>
                                <Grid.Column width={11} verticalAlign={'middle'}>
                                    <div style={style.leaderboardEmail}>{user.fullName || "(" + user.email + ")"}</div>
                                    <div style={style.leaderboardPoints}><i style={style.leaderboardStar} className="icon-star"/>{user.userProfile.points}</div>
                                </Grid.Column>
                            </Grid.Row>
                        ))}
                    </Grid>
                    <div />
                </Card>
                </Card.Group>
            </Grid.Column>

            </Grid.Row>
            </Grid>
            </div>
        )
    );
}

export default withTranslation('dashboard')(Dashboard);
