import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import {Button, Form, Input, Grid, Header, Message, Label, Segment, Modal, Checkbox, Radio} from 'semantic-ui-react';
import Cropper from 'react-cropper';

import Utils from 'utils/Utils.js';
import {colors, spacings, shadow, fontSizes, fonts} from 'style';
// Generic prompt used for deleting content.
// Accepts an argument on launch and passes it to callback on delete selected

var style = {
    btnStyleYes: {
        float: 'right',
        backgroundColor: 'rgb(40, 184, 190)',
        color: '#fff',
        margin: spacings.small,
    },

    btnStyleNo: {
        float: 'right',
        backgroundColor: colors.warningRed,
        color: '#fff',
        margin: spacings.small,
    },

    chkBoxes: {
        margin: spacings.micro,
        fontSize: fontSizes.h4,
    },
    
}

class SelectCustomReports extends Component{
    constructor(props){
        super(props);
        this.state = {
            isOpen : false,
            tag: null
        };
    }

    confirm = () =>{
        this.props.onConfirmation(this.state.tag).then(() => this.closePrompt());
    }

    showPrompt = () => {
        this.setState({
            isOpen : true,
            currentList: []
        });
    }

    closePrompt = () => {
        this.setState({isOpen:false});
    }

    onChange =(event, {value}) =>{
  

        this.setState({tag: value});

    }

    render() {
        if(this.state.isOpen == false){
            return null;
        } 
        return (
            <div>
                 <Modal closeIcon open={this.state.isOpen } size = 'small' onClose = {this.closePrompt} onUnmount={Utils.fixBody}>
                         <Modal.Header>Select Columns</Modal.Header>
                         <Modal.Content style = {style.modalContent}> 
                         {Boolean(this.props.customReports) && this.props.customReports.map(column => (
                            <div key={column.tag}>
                              <Radio name='reports' style = {style.chkBoxes} label={column.name} value = {column.tag} onChange = {this.onChange} checked={this.state.tag == column.tag}/>  
                            </div>
                        ))}

                            
                           

                        </Modal.Content>
                        <Modal.Actions>
                            <Button style={style.btnStyleYes} onClick ={this.confirm} disabled={!this.state.tag}> Download</Button>
                        </Modal.Actions>
                </Modal>

            </div>
            
      );
    }
}
export default SelectCustomReports;